import { BrowserRouter, Routes, Route } from "react-router-dom";
import ComingSoon from "./routes/ComingSoon"
import LandingPage from "./routes/LandingPage";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/landing-page" element={<LandingPage/>} />
          <Route path="/" element={<ComingSoon/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
