import logo from '../assets/images/logo.png';
import './ComingSoon.css';

function ComingSoon() {
  return (
    <div className="ComingSoon">
        <img src={logo} className="logo" alt="logo" />
        <div>
            <p className="title">Wrapped Litecoin is Coming</p>
        </div>
    </div>
  );
}

export default ComingSoon;
